import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, IconButton, Container } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

const data = {
  id: 3,
  nome: 'Site Omnicontent',
  capa: 'https://example.com/images/capa-tecnologia.jpg',
  posicao_capa: 'top-right',
  url: null,
  FeedsVideos: [
    {
      idVideo: 5,
      url: 'https://bucketomnicontent.blob.core.windows.net/bucketforvideos/de71e781-da96-44c4-9399-21f25fabf821.mp4',
      capa: 'https://bucketomnicontent.blob.core.windows.net/bucketforimages/9932b513-2bcf-4cb3-88f1-45e9a2a82d6d-frame.jpg',
      titulo: null,
      cta: null,
      ordem: null,
    },
    {
      idVideo: 6,
      url: 'https://bucketomnicontent.blob.core.windows.net/bucketforvideos/5840a51e-0a2c-4f93-bcb6-29f59fbe3a1e.mp4',
      capa: 'https://bucketomnicontent.blob.core.windows.net/bucketforimages/0eb93171-b4de-484e-83c9-66083536e944-frame.jpg',
      titulo: null,
      cta: null,
      ordem: null,
    },
    {
      idVideo: 7,
      url: 'https://bucketomnicontent.blob.core.windows.net/bucketforvideos/9612ee8d-cf7f-4992-b88e-e08c5a990f69.mp4',
      capa: 'https://bucketomnicontent.blob.core.windows.net/bucketforimages/e7c43864-224f-4738-956c-cabcceb4418a-frame.jpg',
      titulo: null,
      cta: null,
      ordem: null,
    },
    {
      idVideo: 15,
      url: 'https://bucketomnicontent.blob.core.windows.net/bucketforvideos/ed158632-0725-40a0-8822-5407c6a6e8f6.mp4',
      capa: 'https://bucketomnicontent.blob.core.windows.net/bucketforimages/8cc3251b-054c-4161-9e11-061db4e25185-frame.jpg',
      titulo: null,
      cta: null,
      ordem: null,
    },
    {
      idVideo: 16,
      url: 'https://bucketomnicontent.blob.core.windows.net/bucketforvideos/35860530-7953-4358-b2a1-31f265ebebf6.mp4',
      capa: 'https://bucketomnicontent.blob.core.windows.net/bucketforimages/bf74df5b-4d79-41bb-b89e-8ff355a6fcf0-frame.jpg',
      titulo: null,
      cta: null,
      ordem: null,
    },
  ],
};

export const VideoSection = () => {
  const [playingVideo, setPlayingVideo] = useState(null);
  const [videos, setVideos] = useState([]);

  const handlePlayVideo = useCallback((id) => {
    setPlayingVideo(id);
  }, []);

  useEffect(() => {
    setVideos(data?.FeedsVideos);
  }, []);

  return (
    <Box
      sx={{
        background: 'linear-gradient(180deg, #C13584 0%, #FF5C00 100%)',
        py: 8,
        textAlign: 'center',
        color: 'white',
      }}
    >
      <Container maxWidth="xl">
        <Typography variant="body1" sx={{ mb: 2, fontSize: '1.6rem' }}>
          Encurte a jornada da descoberta até a compra
        </Typography>
        <Typography variant="h2" sx={{ mb: 6 }}>
          ESCALABILIDADE E AUTENTICIDADE EM CADA FRAME
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'flex-start', md: videos.length > 5 ? 'flex-start' : 'center' },
            alignItems: 'center',
            overflowX: 'auto',
            gap: 2,
            p: 2,
            scrollSnapType: 'x mandatory',
            scrollBehavior: 'smooth',
            '& > div': {
              scrollSnapAlign: 'center',
            },
          }}
        >
          {videos.map((video) => (
            <Box
              key={video.idVideo}
              sx={{
                position: 'relative',
                minWidth: '253px',
                height: '450px',
                borderRadius: '16px',
                overflow: 'hidden',
                flexShrink: 0,
              }}
            >
              {playingVideo === video.idVideo ? (
                <video src={video.url} controls style={{ width: '80px', height: '100%', borderRadius: '16px' }} />
              ) : (
                <>
                  <img
                    src={video.capa}
                    alt={`Video ${video.idVideo}`}
                    style={{
                      minWidth: '253px',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '16px',
                    }}
                  />
                  <IconButton
                    onClick={() => handlePlayVideo(video.idVideo)}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      color: 'white',
                      fontSize: '64px',
                    }}
                  >
                    <PlayCircleIcon fontSize="inherit" />
                  </IconButton>
                </>
              )}
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};
